import React, {useState} from "react";
import {Helmet} from "react-helmet";
import * as styles from "./style.module.scss";
import {StaticImage} from "gatsby-plugin-image";

import {ButtonUI, InputUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {Layout} from "@components";
import {useSendEvent} from "@tools/hooks";
import {QuizSection} from "@templates/main";

const checkInn = (inn, requestDate) => {
    return fetch("https://statusnpd.nalog.ru/api/v1/tracker/taxpayer_status", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({inn, requestDate}),
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                throw error.message && error.message.message
                    ? error.message.message
                    : error.message;
            }
        );
};

const CheckInnPage = () => {
    const {clickCheckInn} = useSendEvent();

    const [inn, setInn] = useState<string>("");
    const [date, setDate] = useState<any>(new Date().toISOString().split("T")[0]);
    const [message, setMessage] = useState<{ text: string; status: boolean }>({
        text: "",
        status: false,
    });
    const [loading, setLoading] = useState<boolean>(false);

    const checkInnHandler = async () => {
        if (inn.length !== 12) {
            setMessage({text: "Введите корректный ИНН", status: false});
            return;
        }
        if (new Date(date).toString() == "Invalid Date") {
            setMessage({text: "Выберите дату", status: false});
            return;
        }
        try {
            setLoading(true);
            const res = await checkInn(inn, date);
            setMessage({text: res.message, status: res.status === true});
            clickCheckInn();
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    Проверка статуса самозанятого по ИНН на сайте, поиск в реестре самозанятых граждан по фамилии
                </title>
                <meta
                    name="description"
                    content={`Проверить самозанятого по ИНН через базу ФНС. Актуальный реестр самозанятых граждан за ${new Date().getFullYear()} год. Узнать статус НПД: действительно ли и не утрачен. Быстро и удобно`}
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/check-inn"
                />
            </Helmet>
            <Layout>
                <div className={styles.container}>
                    <div className={styles.containerMain}>
                        <TypographyUI.H2
                            variant="h1"
                            swichPoint={LAPTOP_MOBILE_POINTER}
                            className={styles.title}
                        >Проверьте статус самозанятого по ИНН</TypographyUI.H2>

                        <div className={styles.checkInputs}>
                            <div className={styles.checkInputInn}>
                                <InputUI.INN
                                    onChange={setInn}
                                    value={inn}
                                    placeholder="12 цифр"
                                    labelText="ИНН"
                                />
                            </div>

                            <div className={styles.checkInputDate}>
                                <InputUI.Root
                                    type="date"
                                    onChange={setDate}
                                    value={date}
                                    placeholder="Сегодня"
                                    labelText="На дату"
                                />
                            </div>
                        </div>

                        <div className={styles.checkButtonResult}>
                            <ButtonUI
                                className={`${styles.checkButton} ${
                                    loading ? styles.checkButtonLoading : ""
                                }`}
                                onClick={checkInnHandler}
                                disabled={!inn || inn?.length < 12 || !date}
                                $w="280px"
                            >
                                Узнать статус
                            </ButtonUI>
                            <TypographyUI.CaptionMedium
                                className={`${styles.textResult} ${
                                    message.status === true
                                        ? styles.textResultSuccess
                                        : styles.textResultError
                                }`}
                            >
                                {message.text}
                            </TypographyUI.CaptionMedium>
                        </div>
                    </div>
                    <div className={styles.containerFaq}>
                        <StaticImage
                            className={styles.backgroundRubTop}
                            src="../../images/check-inn/rub1.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={64}
                            height={45}
                            layout="fixed"
                            objectFit="contain"
                            alt=""
                        />
                        <StaticImage
                            className={styles.backgroundRubBottom}
                            src="../../images/check-inn/rub2.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={68}
                            height={62}
                            layout="fixed"
                            objectFit="contain"
                            alt=""
                        />
                        <div className={styles.faqCard}>
                            <TypographyUI.H4Bold
                                className={styles.title}
                            >
                                Зачем проверять статус самозанятого
                            </TypographyUI.H4Bold>
                            <TypographyUI.Body2Regular className={styles.description}>
                                Компаниям и ИП выгодно работать с самозанятыми: за внештатных исполнителей на НПД не
                                нужно платить НДФЛ и страховые взносы. Но в любой момент самозанятый может потерять
                                статус. И тогда заказчик автоматически становится его налоговым агентом. Избежать таких
                                ситуаций поможет поиск самозанятого по ИНН.
                            </TypographyUI.Body2Regular>
                        </div>
                        <a
                            className={styles.articlesLink}
                            href="https://самозанятые.рф/blog/"
                        >
                            <TypographyUI.Body1Medium className={styles.articlesLink}>
                                Другие статьи по теме
                            </TypographyUI.Body1Medium>
                        </a>
                    </div>

                </div>

                <div className={styles.containerHow}>
                    <div className={styles.howTitle}><TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>Как
                        выполнить проверку самозанятого</TypographyUI.H2></div>
                    <div className={styles.howDesc}>
                        <TypographyUI.Body1Regular className={styles.howDescText}>Исполнитель может исчезнуть из реестра
                            самозанятых случайно.
                            Например, превысить годовой доход в 2,4 млн рублей. Или сняться с учета через приложение
                            «Мой налог» в два клика. Если это произошло, заказчик должен с каждой выплаты по
                            договору заплатить минимум 43,2% налогов и взносов.</TypographyUI.Body1Regular>
                    </div>

                    <div className={styles.howContent}>
                        <div className={styles.howCards}>
                            <div className={styles.howCard}>
                                <TypographyUI.Body1Regular>Поэтому статус нужно проверять перед каждой выплатой. Для этого можно запросить у
                                исполнителя справку о постановке на учет: ее легко сформировать в «Мой налог». У этого
                                способа есть существенный минус — время, которое вам придется каждый раз тратить на
                                личные переписки и просьбы о справке.</TypographyUI.Body1Regular>
                            </div>
                            <div className={styles.howCard}>
                                <TypographyUI.Body1Regular>Найти самозанятого по ИНН — гораздо надёжнее и быстрее. За пару минут вы узнаете, не
                                потерял ли ваш исполнитель статус НПД и безопасно ли ему платить. В реестре самозанятых
                                по ИНН отображается актуальная на указанную дату информация. Например, если вы
                                проверяете статус перед выплатой, — укажите день, в которой вы будете переводить деньги
                                    самозанятому.</TypographyUI.Body1Regular>
                            </div>
                        </div>
                        <div className={styles.howImageContainer}>
                            <StaticImage className={styles.howImage}
                                src="../../images/check-inn/how-img.png"
                                loading="eager"
                                placeholder="none"
                                formats={["auto", "webp"]}
                                layout="fixed"
                                width={635}
                                height={504}
                                objectFit="contain"
                                alt=""
                            />
                        </div>
                    </div>
                </div>


                <div className={styles.containerPay}>
                    <div className={styles.payContent}>
                        <TypographyUI.H3 className={styles.payTitle}>
                            Как осуществить выплаты самозанятым
                        </TypographyUI.H3>
                        <TypographyUI.Body1Regular className={styles.payDesc}>
                            При работе с несколькими исполнителями можно пользоваться реестром самозанятых граждан вручную. Но чем их больше, тем сложнее следить за актуальным статусом каждого и не допустить ошибки.
                            <br/><br/>С помощью сервиса «Бизнес» вы можете полностью автоматизировать процесс выплат любому количеству исполнителей. Платформа автоматически проверяет самозанятого по ИНН на сайте ФНС.
                        </TypographyUI.Body1Regular>
                        <ButtonUI $w="240px" className={styles.payBtn} onClick={() => {
                            document.querySelector('#quizSection')?.scrollIntoView({behavior: 'smooth'});
                        }}>Попробовать</ButtonUI>
                    </div>

                    <div className={styles.payImg}>
                        <StaticImage
                            src="../../images/check-inn/pay-img1.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={380}
                            height={371}
                            layout="fixed"
                            objectFit="contain"
                            alt=""
                        />
                        <StaticImage style={{'position': 'absolute', 'top': '-15px', 'right': '0px'}}
                                     src="../../images/check-inn/pay-img2.png"
                                     loading="eager"
                                     placeholder="none"
                                     formats={["auto", "webp"]}
                                     width={418}
                                     height={222}
                                     layout="fixed"
                                     objectFit="contain"
                                     alt=""
                        />
                    </div>

                </div>

                {<QuizSection/>}

            </Layout>
        </>
    );
};

export default CheckInnPage;
